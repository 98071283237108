import React from 'react'
import { Row, Col, Button, Progress } from 'antd';

import './index.less';
import { t } from '../../../languages';
import SocialShare from '../../Utils/SocialShare';

const Result = (props) => {

    const uid = props.uid || '';
    const num = props.params.num;
    const is_test = props.is_test;
    const lang = props.lang;

    const show_time = props.show_time || false;

    const hits = props.results.reduce((acc, aR) => acc += aR, 0);
    const errors = num - hits;
    const passed = (errors > props.params.errors ? 0 : 1);
    const totalTime = props.times ? props.times.reduce((acc, aT) => acc += aT, 0) : 0;
    const isTimeout = props.is_timeout || false;
    const coins = props.coins;

    // console.log('Result.render:', props, totalTime, passed);

    const passedmessage = passed ? t(lang, 'bravo') : t(lang, 'oops');
    const passedTest = passed ? t(lang, 'haveovercomelevel') : t(lang, 'nothaveovercomelevel');
    const messagestyle = !passed ? ' error' : ' valid';
    const sound = !passed ? './static/sounds/ohh.mp3' : (is_test ? './static/sounds/godsavequeen.mp3' : './static/sounds/shortapplause.mp3');

    return (
        <Row className="exercise">
            {sound ? <audio src={sound} autoPlay={true} loop={false}></audio> : null}

            <Col span={22} className='progress-bar'>
                <Progress percent={100} showInfo={false} status={passed ? 'success' : 'exception'} />
            </Col>

            <Col span={24} className="result">
                <h3>{passedmessage}</h3>
                {is_test && uid.indexOf('rt') < 0 ? <h4>{passedTest}</h4> : null}
                <ul>
                    {!show_time ? <li>{t(lang, 'havedone')} {num} {t(lang, is_test ? 'tests' : 'exercises')}.</li> : null}
                    {!show_time ? <li>{t(lang, 'havegotten')} <span>{hits}</span> {t(lang, 'hits')}.</li> : null}
                    {!show_time ? <li>{t(lang, 'havemade')} <span>{errors}</span> {t(lang, 'errors')}.</li> : null}

                    {show_time && isTimeout ? <li>{t(lang, 'timeout')}</li> : null}

                    {show_time && !isTimeout && passed ? <li>{t(lang, 'overcomechall')}</li> : null}
                    {show_time && !isTimeout && passed ? <li> <img src="./static/myspace/clock-three.svg" alt="" /> {t(lang, 'in')} <span>{totalTime}</span> {t(lang, 'seconds')}.</li> : null}
                    {show_time && !isTimeout && passed ? <li>{t(lang, 'youearned')}  <img src="./static/myspace/coin.svg" alt="" /> <span>{coins}</span></li> : null}

                    {show_time && !isTimeout && !passed ? <li>{t(lang, 'noovercomechall')}</li> : null}

                </ul>
            </Col>
            <Col span={24}>
                <Row className={'botones result' + messagestyle}>
                    <Col span={12}><Button key="repeat" onClick={props.handleRepeat} type={passed ? null : 'primary'} style={{ 'width': '90%' }}>{t(lang, 'repeat')}</Button></Col>
                    {/* {passed ?  */}<Col span={12}><Button key="submit" type="primary" onClick={props.handleCancel} style={{ 'width': '90%' }}>{t(lang, 'accept')}</Button></Col>{/*  : null} */}
                </Row>
            </Col>
            <Col span={24} className='share-result'>
                <SocialShare lang={lang} />
            </Col>

        </Row >
    )
};

export default Result;
