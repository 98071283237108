import { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Spin } from 'antd';
import plan from '../../data/plan';
import tutor from '../../data/tutor';
import dbMgr from '../../modules/dbManager';
import { videoPreLoader } from '../../modules/preload';
import { USER_POINTS_TO_ASK_REVIEW } from '../../modules/review';
import { generateReinforcementExercises, getNewTutorMessage, getStrengthenExercises, saveUserStrengthen, showTutor } from '../../modules/tutor';
import { ACTIVATE_TUTOR, addDailyGoalReached, RATE_STATE, updateUserFields } from '../../modules/user';
import { getPlainVideos, getSubsetVideos } from '../../utils';
import ModalLoader from '../Course/ModalLoader';
import DailyGoal from '../DailyGoal';
import RequestReview from '../Utils/RequestReview';
import Tutor from './Tutor';

const TutorManager = ({ user, lang, userIsLoading, responseIsFetching, today_time, daily_goal_reached, results, section, updateUserFields, exercise_opened, saveUserStrengthen, showTutor, shown_tutor }) => {

    const [executionMessages, setExecutionMessages] = useState([]);
    const [tutorMessage, setTutorMessage] = useState(null);
    const [preloading, setPreloading] = useState(true);
    const [preloadReady, setPreloadReady] = useState(!ACTIVATE_TUTOR || false);
    const [age, setAge] = useState(1);

    const [modalVisible, setModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const _mustShowReview = () => {
        if (!exercise_opened) {

            const uses = user.uses || 0;
            const rateState = user.rateState || 0;
            const ask = (uses > 0 && (uses % 5 === 0 || uses === 1) || user.points >= USER_POINTS_TO_ASK_REVIEW) && (rateState === null || rateState === RATE_STATE.NONE || rateState === RATE_STATE.LATER);
            // console.log('TutorManager._mustShowReview:', uses, rateState, user.points, '::', ask);
            return ask;
        }
        return false;
    }

    const _mustShowTutor = () => {
        if (!ACTIVATE_TUTOR) return false;

        const lastTutorMess = user.lastTutorMess;
        const last = results.last;
        // console.log('TutorManager._mustShowTutor: results:', results);

        // console.log('TutorManager._mustShowTutor: lastTutorMess::', user.lastTutorMess, '::results last:', last, '::', results.date, results.current, results.current_level, '::exercise_opened:', exercise_opened);

        if (user.dailyGoal && !exercise_opened) {
            const currentAgeVideos = getPlainVideos(tutor[age]);
            const last_uid_n = last ? (`${last.uid}_${last.n}`) : '';
            const newTutorMessage = getNewTutorMessage(last, lastTutorMess, currentAgeVideos, results);
            // console.log('TutorManager._mustShowTutor: newTutorMessage:', newTutorMessage, ':: shown:', shown_tutor, last_uid_n);
            if (newTutorMessage && shown_tutor !== last_uid_n) {
                showTutor(last_uid_n);
                setTutorMessage([newTutorMessage]);
                setModalContent(null);
                return true;
            }
        }

        return false;
    }

    useEffect(() => {
        if (user) {
            // console.log('TutorManager.useEffect[] ...', user);
            setAge(user.age || 1);
        }
    }, []);

    useEffect(() => {
        if (user) {
            // console.log('TutorManager.useEffect[user] ...');
            _evalWhatToShow();
        }
    }, [user]);

    useEffect(() => {
        // console.log('TutorManager.useEffect[exercise_opened] ...');
        _evalWhatToShow();
    }, [exercise_opened]);

    useEffect(() => {
        if (results) {
            // console.log('TutorManager.useEffect[results] ...', results, responseIsFetching);
            _evalWhatToShow();
            _evalWhatToPreload();
        }
    }, [results]);

    const _evalWhatToShow = () => {
        if (results && section === 'learn') {
            const _executionMessages = [];
            if (_mustShowTutor()) _executionMessages.push('tutor');
            if (_mustShowReview()) _executionMessages.push('requestReview');
            setExecutionMessages(_executionMessages);
        } else {
            // console.log('TutorManager.useEffect[results]: no results or !learn ...');
        }
    }

    const _evalWhatToPreload = () => {

        if (ACTIVATE_TUTOR && !preloadReady && results && section === 'learn') {
            const currentAgeVideos = tutor[age];
            const currentLevel = results.current_level;
            // console.log('TutorManager._evalWhatToPreload currentLevel:', currentLevel);

            dbMgr.isReady().then((ready) => {
                const videoSrcs = getSubsetVideos(currentAgeVideos, 0, currentLevel + 2);
                videoPreLoader(videoSrcs, age, _onFinishPreloadHandle).preload();
            }).catch(err => {
                console.log('TutorManager._evalWhatToPreload db error:', err);
            });
        } else {
            setPreloading(false);
        }
    }

    const _onFinishPreloadHandle = (response) => {
        console.log('App._onFinishPreloadHandle preload finished:', response);
        setPreloadReady(true);
    }

    const handleCloseMessage = (who, target) => {
        // console.log('TutorManager handleCloseMessage:', who, target);
        if (who === 'tutor' && target && target.length > 0) {
            const lm = target.pop();
            updateUserFields({ lastTutorMess: lm });
            if (lm.indexOf('nok') >= 0) {
                // console.log('TutorManager handleCloseMessage modalContent:', modalContent);
                const last = results.last;
                const last_uid = last ? ('' + last.uid) : '';
                const test_result = { ...results[last_uid] };
                const agePlan = plan[age];
                const currentLevel = results.current_level;

                const uniqueExToImprove = getStrengthenExercises(test_result);
                const toImproveExers = generateReinforcementExercises(agePlan, currentLevel, uniqueExToImprove);
                saveUserStrengthen(currentLevel, toImproveExers);
            }
        }

        if (executionMessages && executionMessages.length) {
            const _executionMessages = [...executionMessages];
            _executionMessages.shift();

            setExecutionMessages(_executionMessages);
        }
    }

    const handleCancel = e => {
        // console.log('Learn.handleCancel', e);
        setModalContent(null);
        setModalVisible(false);
    };

    const handleOk = e => {
        // console.log('Learn.handleOk', e);
        setModalContent(null);
        setModalVisible(false);
    };

    // console.log('TutorManager executionMessages:', executionMessages, executionMessages.length);

    if (modalContent) return <ModalLoader
        title={''}
        visible={modalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        content={modalContent}
        className="exercise-modal"
    />;
    else return <>
        {preloading && !preloadReady ? <Spin size="large" className="spinner" /> : null}
        {(preloadReady) ? <DailyGoal handleCloseManager={handleCloseMessage} /> : null}
        {(preloadReady && executionMessages[0] === 'requestReview') ? <RequestReview handleCloseManager={handleCloseMessage} /> : null}
        {(preloadReady && executionMessages[0] === 'tutor') ? <Tutor lang={lang} age={age} themes={tutorMessage} handleCloseManager={handleCloseMessage} /> : null}
    </>;
}


const mapStateToProps = ({ user, response, tutor }) => ({
    user: user.user,
    lang: user.lang,
    today_time: user.today_time,
    daily_goal_reached: user.daily_goal_reached,
    exercise_opened: user.exercise_opened,
    results: response.data,
    userIsLoading: user.isLoading,
    responseIsFetching: response.isFetching,
    shown_tutor: tutor.shown_tutor
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            updateUserFields,
            addDailyGoalReached,
            saveUserStrengthen,
            showTutor
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(TutorManager);
