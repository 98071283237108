import { Button, Modal } from "antd";
import { useEffect, useState } from "react";
import { t } from "../../languages";

import dbMgr from "../../modules/dbManager";
import './index.less';
import { BASE_VIDEO_URL } from "../../modules/user";

let timer;
const WAITTIME = 300;
const WAITNEXTVIDEO = 100;

const Tutor = ({ lang, age, themes, handleCloseManager }) => {
    const [open, setOpen] = useState(false);
    const [playing, setPlaying] = useState(false);
    const [ended, setEnded] = useState(false);
    const [blob, setBlob] = useState(null);
    const [videoUrl, setVideoUrl] = useState(null);
    const [currentThemeIdx, setCurrentThemeIdx] = useState(0);

    useEffect(() => {
        // console.log('Tutor.pRef:', pRef.current);
        const vkey = themes[currentThemeIdx];
        _readVideoFromDB(vkey);

        timer = setTimeout(() => {
            setOpen(true);
        }, WAITTIME);

        return () => {
            clearTimeout(timer);
        }
    }, []);

    const _readVideoFromDB = (vkey) => {
        dbMgr.readFromDb(vkey).then((blob) => {
            const videoBlob = URL.createObjectURL(blob);
            setBlob(videoBlob);
            setPlaying(true);

        }).catch((err) => {
            // console.log('Tutor._readVideoFromDB... Loading from url...');
            const url = `${BASE_VIDEO_URL}/age${age}/${vkey}.mp4`;
            setVideoUrl(url);
        });
    }

    const handleCancel = () => {
        // setOpen(false);
    };

    const handleAccept = () => {
        if (currentThemeIdx < themes.length - 1) {
            const vkey = themes[currentThemeIdx + 1];
            _readVideoFromDB(vkey);
            setCurrentThemeIdx(currentThemeIdx + 1);
            // setPlaying(true);
            setEnded(false);
        }
        else setOpen(false);
    };

    const handleStrengthen = () => {
        console.log('Tutor.handleStrengthen....');
        setOpen(false);
    };


    const handleReset = () => {
        handleCloseManager('tutor', themes);
    };

    const handleReady = () => {
        // const video = pRef.current.getInternalPlayer();
    }

    const handleProgress = (progress) => {
        // console.log('Tutor.progress:', progress);
    }

    const handleEnd = () => {
        // console.log('Tutor.handleEnd...');
        setEnded(true);
    }

    const handleError = () => {
        // console.log('Tutor.handleError...');
        setEnded(true);
    }


    const currentTheme = themes[currentThemeIdx];
    // console.log('Tutor.currentTheme:', currentTheme);
    const showPractice = currentTheme.indexOf('nok') >= 0 || false;

    return <>

        <Modal
            open={open}
            title={null}
            onCancel={handleCancel}
            footer={null}
            className='tutor-modal confirm'
            afterClose={handleReset}
            closable={false}
            destroyOnClose={true}
        >
            <div className="body">
                {open && (blob || videoUrl) ?
                    <video
                        src={blob || videoUrl}
                        muted={false} //false in PROD
                        autoPlay={playing}
                        controls={true}
                        onEnded={handleEnd}
                        onError={handleError}
                        style={{ width: 'auto', height: '50vh' }}
                    /> : null}
                <h3>Koyu: {themes[currentThemeIdx]}</h3>
            </div>
            <div className='botonera'>
                {showPractice ? <Button onClick={handleStrengthen} disabled={!ended} >{t(lang, 'strengthen')}</Button> : <Button key={currentTheme} onClick={handleAccept} disabled={!ended} >{t(lang, 'ok')}</Button>}
            </div>
        </Modal>
    </>;
}

export default Tutor;
