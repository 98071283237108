import moment from "moment";
import { API_ROOT } from "../../api-config";
import { swapObjectKeysValues } from "../../utils";
import { genExerciseComponent } from "../../utils/componentwrapper";
import { GAME_IDS } from "../response";
import { fetch_options } from "../utils";
import { LOGOUT } from "../user";

export const LOAD_USER_STRENGTHEN = 'tutor/LOAD_USER_STRENGTHEN';
export const RECEIVED_USER_STREGNTHEN = 'tutor/RECEIVED_USER_STREGNTHEN';
export const SHOW_TUTOR = 'tutor/SHOW_TUTOR';


const initialState = {
    isLoading: false,
    error: false,
    strengthen_units: null,
    shown_tutor: 0
};

export default (state = initialState, action) => {
    switch (action.type) {

        case LOAD_USER_STRENGTHEN:
            return {
                ...state,
                isLoading: true,
                error: false
            };

        case RECEIVED_USER_STREGNTHEN:
            return {
                ...state,
                strengthen_units: action.strengthen,
                isLoading: false,
                error: action.error,
            };

        case SHOW_TUTOR:
            return {
                ...state,
                shown_tutor: action.last_uid_n,
            };

        case LOGOUT:
            localStorage.removeItem('strengthen');

            return initialState;
        default:
            return state;
    }
};

export const getNewTutorMessage = (last, lastTutorMess, currentAgeVideos, results) => {
    const last_uid = last ? ('' + last.uid) : '';
    const last_level = last ? last.l : 1;
    const current_level = results.current_level || 1;

    // console.log('tutor.getNewTutorMessage results:', results, ':: levels:', last_level, current_level);

    let newMessage = null;
    if (!lastTutorMess) {
        newMessage = currentAgeVideos.welcome;
    } else if (last && last_uid.indexOf('t') >= 0) {
        const test_result = { ...results[last_uid] };
        const test_prev_history = test_result.rh.slice(0, -1);
        const has_prev_ps = test_prev_history.includes(1);
        const has_prev_np = test_prev_history.includes(0);
        const vid_uid_ok = currentAgeVideos[`${last_uid}_ok`];
        const vid_uid_nok = currentAgeVideos[`${last_uid}_nok`];
        const vid_uid_nok_2 = currentAgeVideos[`${last_uid}_nok_2`];

        // console.log('tutor.mustShowTutor last_uid:', last_uid, test_prev_history, '::', test_result.p, has_prev_ps, has_prev_np, '::', lastTutorMess);

        if (test_result.p && !has_prev_ps && lastTutorMess !== vid_uid_ok && last_level < current_level) { newMessage = vid_uid_ok; }
        if (!test_result.p && last_level === current_level) {
            if(has_prev_np) newMessage = vid_uid_nok_2;
            else newMessage = vid_uid_nok;
        }
    }

    return newMessage;
}

export const getStrengthenExercises = (testResult) => {
    if (testResult) {
        const testExers = testResult.ex;
        const testSeries = testResult.s;
        const testSeriesResults = testResult.sr;
        const toImprove = [];
        const uniqueExToImprove = [];
        for (let idx = 0; idx < testSeriesResults.length; idx++) {
            const seriresImprove = [];
            const seriresResults = testSeriesResults[idx];
            for (let jdx = 0; jdx < seriresResults.length; jdx++) {
                if (!seriresResults[jdx]) seriresImprove.push(testSeries[idx][jdx]);
            }
            if (seriresImprove.length > 0) {
                toImprove.push({ ex: testExers[idx], series: seriresImprove });
                if (!uniqueExToImprove.includes(testExers[idx])) uniqueExToImprove.push(testExers[idx]);
            }
        }
        // console.log('tutor.StrengthenExercises testResult:', testResult, '::  toImprove:', toImprove, uniqueExToImprove);
        return uniqueExToImprove;
    } else return null;
}

export const generateReinforcementExercises = (agePlan, level, uniqueExToImprove) => {
    if (uniqueExToImprove) {
        const agetest = agePlan[level - 1].test;
        const INV_GAME_IDS = swapObjectKeysValues(GAME_IDS);

        const toImproveExers = [];

        for (let idx = 0; idx < agetest.exercises.length; idx++) {
            const aExer = agetest.exercises[idx];
            const exerImplCode = INV_GAME_IDS[aExer.impl];
            if (uniqueExToImprove.includes(exerImplCode)) {
                const aTestExer = { ...aExer, icon: 'strengthen' };
                aTestExer.params = { ...aTestExer.params, num: 3, coins: 5 };
                toImproveExers.push(aTestExer);
            }
        }

        console.log('tutor.generateReinforcementTest toImproveExers:', toImproveExers);
        return toImproveExers;
    } else return null;
}

export const generateReinforcementTest = (tid, agePlan, level, uniqueExToImprove, setModalContent, handleCancel, saveResult, lang) => {
    if (uniqueExToImprove) {
        const toImproveExers = generateReinforcementExercises = (agePlan, level, uniqueExToImprove);
        setModalContent(genExerciseComponent('test', `r${tid}`, 0, toImproveExers, handleCancel, saveResult, lang));
    }
}

const loadStorageStrengthen = () => {
    const strengthenstr = localStorage.getItem('strengthen');
    let strengthen = strengthenstr ? JSON.parse(strengthenstr) : null;
    return strengthen;
}

export function loadUserStrengthen(activeChild) {

    // console.log('tutor.loadUserStrengthen:', activeChild);

    return function (dispatch, getState) {

        dispatch({
            type: LOAD_USER_STRENGTHEN
        });

        const url = `${API_ROOT}/userstrengthen/${activeChild}`;
        return fetch(url, fetch_options)
            .then(response => {
                if (response.ok) return response.json();
                else if (response.status === 404) return null;
                else throw new Error(response);
            })
            .then(response => {
                // console.log('tutor.loadUserStrengthen recived:', response);
                const strengthen = response && response.strengthen && Object.keys(response.strengthen).length > 0 ? response.strengthen : null;
                localStorage.setItem('strengthen', JSON.stringify(strengthen));

                dispatch({
                    type: RECEIVED_USER_STREGNTHEN,
                    strengthen: response ? response.strengthen : loadStorageStrengthen(),
                    receivedAt: moment()
                });

            })
            .catch(error => {
                console.error('tutor.loadUserStrengthen error fetching:', error, 'loading from local');
                dispatch({
                    type: RECEIVED_USER_STREGNTHEN,
                    response: loadStorageStrengthen(),
                    receivedAt: moment()
                });
            });
    }
}

const storeStrengthen = (strengthen_units) => {
    if (strengthen_units) {
        const strengthen_units_str = JSON.stringify(strengthen_units);
        localStorage.setItem('strengthen', strengthen_units_str);
        return strengthen_units_str;
    } else return null;
}

export const saveUserStrengthen = (level, strengthen_unit) => {
    return (dispatch, getState) => {

        const userStore = getState().user;
        const _user = userStore.user;
        const activeChild = _user ? (_user.isParent ? _user.activeChild : _user.id) : null;
        // console.log('strengthen.saveUserStrengthen:', level, strengthen_unit);

        const tutorStore = getState().tutor;
        const _strengthen_units = tutorStore.strengthen_units || {};
        _strengthen_units[level] = strengthen_unit;

        const strengthenStr = storeStrengthen(_strengthen_units);

        dispatch({
            type: RECEIVED_USER_STREGNTHEN,
            strengthen: JSON.parse(strengthenStr),
            receivedAt: moment()
        });

        dispatch({
            type: LOAD_USER_STRENGTHEN,
        });

        const _fetch_options = {
            ...fetch_options,
            method: 'POST',
            body: strengthenStr
        };

        const url = `${API_ROOT}/userstrengthen/${activeChild}`;
        return fetch(url, _fetch_options)
            .then(response => {
                if (response.ok) return response.json();
                else throw new Error(response);
            })
            .then(response => {
                console.log('saveUserStrengthen recived:', response);

                dispatch({
                    type: RECEIVED_USER_STREGNTHEN,
                    strengthen: response.strengthen,
                    receivedAt: moment()
                });

            })
            .catch(error => {
                console.error('saveUserStrengthen error fetching:', error, ':: Saving from local');
            });

    }
}

export const showTutor = (last_uid_n) => {
    return (dispatch, getState) => {
        dispatch({
            type: SHOW_TUTOR,
            last_uid_n
        });
    }
}
